import { gql } from '@apollo/client';

export const CORE_TAG_FIELDS = gql`
  fragment CorseFields on Tag {
    key
    name
    isActive
  }
`;

export const GET_TAGS = gql`
  ${CORE_TAG_FIELDS}
  query tagsAdmin(
    $filter: TagsFilter
    $sort: TagsSort
    $where: TagsWhereInput
  ) {
    tagsAdmin(filter: $filter, sort: $sort, where: $where) {
      count
      tags {
        ...CorseFields
      }
    }
  }
`;

export const GET_TAG = gql`
  ${CORE_TAG_FIELDS}
  query tagAdmin($key: String!) {
    tagAdmin(where: { key: $key }) {
      ...CorseFields
      slug
      metaHeader
      metaFooter
      page {
        id
        isDefault
      }
      ottPage {
        id
        isDefault
      }
      mobilePage {
        id
        isDefault
      }
      rokuPage {
        id
        isDefault
      }
    }
  }
`;
