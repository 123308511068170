import { IdcardOutlined, LoginOutlined } from '@ant-design/icons';
import { Menu, Tag, Tooltip } from 'antd';
import { forEach } from 'lodash';
import React, { useEffect, useState } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { APP_VERSION, MODULES, ROUTES } from '../../../common/constants';
import UserProfile from '../header/UserProfile';
import './sidebar.less';

function Sidebar({ location: { pathname }, history, collapsed }) {
  const [openKeys, setOpenKeys] = useState([]);
  const onMenuSelect = ({ key }) => {
    history?.push(key);
  };

  function getItem({ label, key, icon, children, type }) {
    return {
      key,
      icon,
      children,
      label,
      type
    };
  }

  const menuItems = [
    getItem({
      label: MODULES?.ASSETS,
      key: MODULES?.ASSETS,
      children: [
        getItem({ label: MODULES?.IMAGES, key: ROUTES?.IMAGES }),
        getItem({ label: MODULES?.VIDEOS, key: ROUTES?.VIDEOS }),
        getItem({ label: MODULES?.DOCUMENT, key: ROUTES?.DOCUMENT }),
        getItem({ label: MODULES?.AUDIO, key: ROUTES?.AUDIO }),
        getItem({ label: MODULES?.TEXT, key: ROUTES?.TEXT }),
        getItem({ label: MODULES?.ICONS, key: ROUTES?.ICONS })
      ]
    }),
    getItem({
      label: MODULES?.LABELS,
      key: MODULES?.LABELS,
      children: [
        getItem({ label: MODULES?.TOPICS, key: ROUTES?.TOPICS }),
        getItem({ label: MODULES?.TAGS, key: ROUTES?.TAGS }),
        getItem({ label: MODULES?.AUTHOR, key: ROUTES?.AUTHOR }),
        getItem({ label: MODULES?.COLLECTIONS, key: ROUTES?.COLLECTIONS })
      ]
    }),
    getItem({
      label: MODULES?.VIDEOS,
      key: ROUTES?.VIDEOS_MODULE
    }),
    getItem({
      label: MODULES?.MENUS,
      key: ROUTES?.MENUS
    }),
    getItem({
      label: MODULES?.PAGES,
      key: ROUTES?.PAGES_MODULE
    }),
    getItem({
      label: MODULES?.CONVERSATIONS,
      key: ROUTES?.CONVERSATIONS
    }),
    getItem({
      label: MODULES?.DONATIONS,
      key: ROUTES?.DONATIONS
    }),
    getItem({
      label: MODULES?.USERS,
      key: ROUTES?.USERS
    }),
    getItem({
      label: MODULES?.UI_LABELS,
      key: ROUTES?.UI_LABELS
    }),
    getItem({
      label: MODULES?.LEAD_GENS,
      key: ROUTES?.LEAD_GENS
    }),
    getItem({
      label: MODULES?.CONTACT_INQUIRY,
      key: ROUTES?.CONTACT_INQUIRY
    }),
    getItem({
      label: MODULES?.EXPORT_JOBS,
      key: ROUTES?.EXPORT_JOBS
    }),
    getItem({
      label: MODULES?.IMPORT_JOBS,
      key: ROUTES?.IMPORT_JOBS
    })
  ];

  const profileItems = [
    getItem({
      label: <UserProfile collapsed={collapsed} />,
      key: MODULES?.PROFILE,
      children: [
        getItem({
          label: <Link to={ROUTES?.PROFILE}>My Profile</Link>,
          key: ROUTES?.PROFILE,
          icon: <IdcardOutlined />
        }),
        getItem({
          label: <Link to={ROUTES?.LOGOUT}>Logout</Link>,
          key: ROUTES?.LOGOUT,
          icon: <LoginOutlined />
        })
      ]
    })
  ];

  const subMenus = [
    {
      moduleKey: MODULES?.ASSETS,
      routes: [
        ROUTES?.MAIN,
        ROUTES?.AUDIO,
        ROUTES?.DOCUMENT,
        ROUTES?.VIDEOS,
        ROUTES?.TEXT,
        ROUTES?.ICONS
      ]
    },
    {
      moduleKey: MODULES?.LABELS,
      routes: [
        ROUTES?.TOPICS,
        ROUTES?.TAGS,
        ROUTES?.AUTHOR,
        ROUTES?.COLLECTIONS
      ]
    }
  ];

  const getOpenKeys = () => {
    const openKeysClone = openKeys;
    forEach(subMenus, (item) => {
      forEach(item?.routes, (route) => {
        if (pathname?.includes(route)) {
          openKeysClone?.push(item?.moduleKey);
          setOpenKeys(openKeysClone);
        }
      });
    });
  };

  useEffect(() => {
    getOpenKeys();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  return (
    <div className="sidebar">
      <Menu
        className="menu-bar"
        theme="light"
        mode="inline"
        selectedKeys={[`/${pathname?.split('/')?.[1]}`]}
        defaultSelectedKeys={[ROUTES?.MAIN]}
        items={menuItems}
        inlineIndent={0}
        openKeys={openKeys}
        onOpenChange={(openKeysArray) => setOpenKeys(openKeysArray)}
        onClick={onMenuSelect}
      />
      <div className="profile-menu">
        {collapsed ? (
          <Tooltip title={`v${APP_VERSION}`} placement="right">
            <Tag className="app-version">v</Tag>
          </Tooltip>
        ) : (
          <Tag className="app-version">v{APP_VERSION}</Tag>
        )}
        <Menu
          className="profile-bar"
          theme="light"
          selectedKeys={[`/${pathname?.split('/')?.[1]}`]}
          items={profileItems}
        />
      </div>
    </div>
  );
}

export default withRouter(Sidebar);
